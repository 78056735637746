import React, { useReducer } from 'react';
import AppContext from './AppContext';
import AppReducer from './AppReducer';

const AppState = (props) => {
    const initialState = {
        // Title-related state
        currentTitle: 'Input a video at the top place to generate a youtube title',
        generatedTitles: [],
        titleLoading: false,

        // Description-related state
        currentDescription: 'Input a video at the top place to generate a youtube description',
        generatedDescriptions: [],
        descriptionLoading: false,

        // Tags-related state
        currentTags: ["Input a video at the top place to generate youtube tags"],
        generatedTags: [],
        tagsLoading: false,

        // Transcription-related state
        transcriptionText: 'Input a video at the top place to get your video transcription and subtitle file',
        transcriptionSrt: '',
        transcriptionLoading: false,

        // User-related state
        user: null,
        transcriptionCredits: 0,
        generationCredits: 0,
        subscription: null,

        // // Video-related state
        // currentVideo: null,
        // videoProcessing: false,

        // Error state
        error: null,
    };

    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Action creators
    // Title actions
    const setTitle = (title) => dispatch({ type: 'SET_TITLE', payload: title });
    const setGeneratedTitles = (titles) => dispatch({ type: 'SET_GENERATED_TITLES', payload: titles });
    const setTitleLoading = (loading) => dispatch({ type: 'SET_TITLE_LOADING', payload: loading });

    // Description actions
    const setDescription = (description) => dispatch({ type: 'SET_DESCRIPTION', payload: description });
    const setGeneratedDescriptions = (descriptions) => dispatch({ type: 'SET_GENERATED_DESCRIPTIONS', payload: descriptions });
    const setDescriptionLoading = (loading) => dispatch({ type: 'SET_DESCRIPTION_LOADING', payload: loading });

    // Tags actions
    const setTags = (tags) => dispatch({ type: 'SET_TAGS', payload: tags });
    const setGeneratedTags = (tags) => dispatch({ type: 'SET_GENERATED_TAGS', payload: tags });
    const setTagsLoading = (loading) => dispatch({ type: 'SET_TAGS_LOADING', payload: loading });

    // Transcription actions
    const setTranscription = (text) => dispatch({ type: 'SET_TRANSCRIPTION', payload: text });
    const setTranscriptionSRT = (srt) => dispatch({ type: 'SET_TRANSCRIPTION_SRT', payload: srt });
    const setTranscriptionLoading = (loading) => dispatch({ type: 'SET_TRANSCRIPTION_LOADING', payload: loading });

    // User actions
    const setUser = (user) => dispatch({ type: 'SET_USER', payload: user });
    const setTranscriptionCredits = (transcriptionCredits) => dispatch({ type: 'SET_TRANSCRIPTION_CREDITS', payload: transcriptionCredits });
    const setGenerationCredits = (generationCredits) => dispatch({ type: 'SET_GENERATION_CREDITS', payload: generationCredits });
    const setSubscription = (subscription) => dispatch({ type: 'SET_SUBSCRIPTION', payload: subscription });

    // Error action
    const setError = (error) => dispatch({ type: 'SET_ERROR', payload: error });

    return (
        <AppContext.Provider
            value={{
                ...state,
                setTitle,
                setGeneratedTitles,
                setTitleLoading,
                setDescription,
                setGeneratedDescriptions,
                setDescriptionLoading,
                setTags,
                setGeneratedTags,
                setTagsLoading,
                setTranscription,
                setTranscriptionSRT,
                setTranscriptionLoading,
                setUser,
                setTranscriptionCredits,
                setGenerationCredits,
                setSubscription,
                setError,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppState;
import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import SubscriptionButton from '../../services/SubscriptionManager';
import { useAuth } from '../../context/AuthContext';
import { sha256 } from 'js-sha256';

const PricingComponent = () => {
    const { user } = useAuth()
    const subscriptionPlans = [
        // { name: '30 videos', price: '$8/mo', features: ['30 transcriptions', '1000 generations'], variantId: "463546" }, // this is the test variant
        { name: '30 videos', price: '$8/mo', features: ['30 transcriptions', '1000 generations'], variantId: "477607" },
        // { name: '30 videos', price: '$16.80/mo', features: ['30 transcription', '5000 generations'] },
        // { name: '100 videos', price: '$29.90/mo', features: ['100 transcription', '20000 generation', 'Feature 7'] },
    ];

    const oneTimePlan = {
        name: '30 videos',
        price: '$8 one time',
        features: ['30 transcriptions', '1000 generations', 'No recurring fees'],
    };

    const cardStyle = {
        width: '100%',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    const headerStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px',
    };

    const priceStyle = {
        fontSize: '20px',
        color: '#1890ff',
        marginBottom: '20px',
    };

    const featureListStyle = {
        listStyleType: 'none',
        padding: 0,
        margin: '0 0 20px 0',
    };
//  

    const createNMKRPaymentLink = () => {
        // const baseUrl = 'https://nmkr.io/payment/';
        const baseUrl = 'https://pay.nmkr.io/?p=e5b6e6f410914fe3b8202b4f359aa2af&c=1';
        const baseUrlPreProd = 'https://pay.preprod.nmkr.io/?p=84ef5c42c8bd4c69b58292c3f14b5223&c=1';
        // const projectId = 'your_project_id';
        console.log("user.id", user.uid)
        // const hashedUserId = hashUserId(user.uid);
        const hashedUserId = user.uid;

        return `${baseUrl}&cp=${hashedUserId}`;
    }

    function hashUserId(userId) {
        return sha256(userId);
    }

    const handleNMKRPayment = () => {
        const paymentUrl = createNMKRPaymentLink();
        window.open(paymentUrl, '_blank');
    }


    return (
        <div style={{ padding: '20px', justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "32px", textAlign: "center", fontWeight: "600", color: "#3C3C3C" }}>Subscription</span>
            <br />
            <br />
            <Row gutter={[16, 16]} justify="center">
                {subscriptionPlans.map((plan, index) => (
                    <Col xs={24} sm={12} key={index}>
                        <Card style={cardStyle}>
                            <div>
                                <h3 style={headerStyle}>{plan.name}</h3>
                                <p style={priceStyle}>{plan.price}</p>
                                <ul style={featureListStyle}>
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                            <SubscriptionButton variantId={plan.variantId} style={{ width: '80%', margin: '0 auto' }} />
                            {/* <Button onClick={() => {}} type="primary" style={{ width: '80%', margin: '0 auto' }}>
                                Subscribe
                            </Button> */}
                        </Card>
                    </Col>
                ))}
            </Row>
            <br />
            <br />
            <br />
            <br />
            <span style={{ fontSize: "32px", textAlign: "center", fontWeight: "600", color: "#3C3C3C" }}>One time purchase</span>
            <br />
            <br />
            <Row style={{ marginTop: '20px' }}>
                <Col xs={24} sm={{ span: 16, offset: 4 }} md={{ span: 12, offset: 6 }}>
                    <Card style={cardStyle}>
                        <div>
                            <h3 style={headerStyle}>{oneTimePlan.name}</h3>
                            <p style={priceStyle}>{oneTimePlan.price}</p>
                            <ul style={featureListStyle}>
                                {oneTimePlan.features.map((feature, idx) => (
                                    <li key={idx}>{feature}</li>
                                ))}
                            </ul>
                        </div>
                        {/* <Button style={{ width: '80%', margin: '0 auto', marginBottom: '10px', }}>
                            Buy with Stripe
                        </Button> */}
                        <Button onClick={() => handleNMKRPayment()} type="primary" style={{ width: '80%', margin: '0 auto' }}>
                            Buy with crypto
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default PricingComponent;
import { CopyOutlined, LeftOutlined, LoadingOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Spin, message } from 'antd';
import AppContext from '../../context/AppContext';
import { handleTitleRequest } from '../../firebase/genTitle';

const words = ["transcription", "description", "title", "tags"];


const TitleComponent = () => {
    const {
        setTitle,
        setTitleLoading,
        currentTitle,
        generatedTitles,
        setGeneratedTitles,
        titleLoading,
        transcriptionText

    } = useContext(AppContext)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeout(() => {
                // setIndex((prevIndex) => (prevIndex + 1) % words.length);
                // setFade(false);
            }, 200); // Wait for fade out, then change word
        }, 1000); // Change every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    const copyToClipboard = () => {
        if (currentTitle) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                // Modern browsers
                navigator.clipboard.writeText(currentTitle)
                    .then(() => {
                        message.success('Title copied to clipboard');
                    })
                    .catch(err => {
                        fallbackCopyTextToClipboard(currentTitle);
                    });
            } else {
                // Fallback for older browsers and some mobile devices
                fallbackCopyTextToClipboard(currentTitle);
            }
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                message.success('Title copied to clipboard');
            } else {
                message.error('Failed to copy title');
            }
        } catch (err) {
            message.error('Failed to copy title');
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const displayCopyIcon = () => {
        // if (generatedTitles?.length > 0) {
        return (
            <div style={{ paddingLeft: "16px" }}>
                <CopyOutlined onClick={copyToClipboard} style={{ fontSize: "24px", color: "#777777" }} />
            </div>
        )
        // } else {
        // return null
        // }
    }

    const displayLeftIcon = () => {
        const currentIndex = generatedTitles?.indexOf(currentTitle) ?? 0;
        if (generatedTitles?.length > 0 && currentIndex !== 0) {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <LeftOutlined onClick={selectPrevious} style={{ fontSize: "24px", color: "#777777" }}></LeftOutlined>
                </div>
            )
        } else {
            return null
        }
    }

    const handleAddTitle = async () => {
        try {
            setTitleLoading(true)
            // Generate initial title
            const initialTitle = await handleTitleRequest(transcriptionText);
            // const initialTitle = "add a new title"
            setTitleLoading(false);
            setTitle(initialTitle)
            setGeneratedTitles([...generatedTitles, initialTitle]);
            return initialTitle
        } catch (error) {
            if (error.message === 'Insufficient credits') {
                message.error("Buy credits on the account page")
            } else {
                message.error("Failed to generate a title")
            }
            console.error('Error generating a title:', error);
            setTitleLoading(false);
        }
    }

    const displayRightIcon = () => {
        if (currentTitle === generatedTitles?.[generatedTitles?.length - 1]) {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <PlusOutlined onClick={handleAddTitle} style={{ fontSize: "24px", color: "#777777" }}></PlusOutlined>
                </div>
            )
        } else if (generatedTitles?.length < 1 && transcriptionText !== 'Input a video at the top place to get your video transcription and subtitle file') {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <PlusOutlined onClick={handleAddTitle} style={{ fontSize: "24px", color: "#777777" }}></PlusOutlined>
                </div>
            )
        } else if (generatedTitles?.length < 1) {
            return null
        } else {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <RightOutlined onClick={selectNext} style={{ fontSize: "24px", color: "#777777" }}></RightOutlined>
                </div>
            )
        }
    }

    const displayLoading = () => {
        return (
            <div style={{ alignItems: "center", display: "flex" }}>
                <Spin indicator={<LoadingOutlined spin />} style={{ paddingRight: "16px" }} /> Loading...
            </div>
        )
    }

    const selectPrevious = () => {
        const currentIndex = generatedTitles?.indexOf(currentTitle) ?? 0;

        if (currentIndex !== -1) {
            const previousIndex = (currentIndex - 1 + generatedTitles?.length) % generatedTitles?.length;
            setTitle(generatedTitles[previousIndex]);
        }
    }
    const selectNext = () => {
        const currentIndex = generatedTitles.indexOf(currentTitle);

        if (currentIndex !== -1) {
            const nextIndex = (currentIndex + 1) % generatedTitles?.length;
            setTitle(generatedTitles[nextIndex]);
        }
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <h2 style={{ color: "#3C3C3C" }}>Title</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {displayCopyIcon()}
                    {displayLeftIcon()}
                    {displayRightIcon()}
                </div>
            </div>
            <div style={{ border: "solid lightgray 1px", borderRadius: "8px", backgroundColor: "white", width: "100%", padding: "16px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C" }}>{titleLoading ? displayLoading() : currentTitle}</span>
            </div>
            <br />
            {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button size='small' icon={<PlusOutlined />} onClick={() => {}}>Add example</Button>
               
            </div> */}
        </div>
    );
};

export default TitleComponent;
import React, { useContext } from 'react';
import { Button, Spin, message } from 'antd';
import { CopyOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import AppContext from '../../context/AppContext';

const TranscriptionComponent = () => {
    const {
        transcriptionText,
        transcriptionSrt,
        transcriptionLoading
    } = useContext(AppContext)

    const copyToClipboard = () => {
        if (transcriptionText) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(transcriptionText)
                    .then(() => message.success('Transcription copied to clipboard'))
                    .catch(err => {
                        fallbackCopyTextToClipboard(transcriptionText);
                    });
            } else {
                // Fallback for older browsers and some mobile devices
                fallbackCopyTextToClipboard(transcriptionText);
            }
        }
    };
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                message.success('Transcription copied to clipboard');
            } else {
                message.error('Failed to copy transcription');
            }
        } catch (err) {
            message.error('Failed to copy transcription');
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const downloadSRT = () => {
        if (transcriptionSrt) {
            const blob = new Blob([transcriptionSrt], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'subtitles.srt';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const displayLoading = () => {
        return (
            <div style={{ alignItems: "center", display: "flex" }}>
                <Spin indicator={<LoadingOutlined spin />} style={{ paddingRight: "16px" }} /> Loading...
            </div>
        )
    }

    const displayCopyIcon = () => {
        if (transcriptionText) {
            return (
                <div style={{ paddingLeft: "16px", alignContent: "center" }}>
                    <CopyOutlined onClick={copyToClipboard} style={{ fontSize: "24px", color: "#777777" }} />
                </div>
            )
        } else {
            return null
        }
    }

    const downloadSrt = (srtString, filename = 'subtitle.srt') => {
        const content = srtString;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Append to the document body to make it work in Firefox
        document.body.appendChild(link);

        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const displayDownloadIcon = () => {
        if (transcriptionSrt) {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <Button icon={<DownloadOutlined />} onClick={() => downloadSrt(transcriptionText)}>Download SRT</Button>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <h2 style={{ color: "#3C3C3C" }}>Transcription</h2>
                <div style={{ display: "flex", alignContent: "center" }}>
                    {displayCopyIcon()}
                    {displayDownloadIcon()}
                </div>
            </div>
            <div style={{
                border: "solid lightgray 1px", borderRadius: "8px", backgroundColor: "white", width: "100%", padding: "16px",
                maxHeight: "450px",
                overflowY: "auto"
            }}>
                <style>
                    {`
        /* Webkit browsers like Chrome, Safari */
        div::-webkit-scrollbar {
            width: 8px;
        }
        div::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }
        div::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
        }
        div::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        /* Firefox */
        div {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
        }
        `}
                </style>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C" }}>{transcriptionLoading ? displayLoading() : (
                    <div style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#3C3C3C",
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        overflowWrap: "break-word"
                    }}>
                        {transcriptionText.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                {index < transcriptionText.split('\n').length - 1 && <br />}
                            </React.Fragment>
                        ))}
                    </div>
                )}</span>
            </div>
        </div>
    );
};

export default TranscriptionComponent;
import { message } from "antd";
import { auth } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export async function handleTagsRequest(transcriptionText) {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Call the Firebase function
    const generateTags = httpsCallable(functions, 'generateTags');
    try {
        const result = await generateTags({ transcription: transcriptionText });
        console.log("generateTags result :", result)
        console.log("generateTags result.data.tags :", result.data.tags)
        return result.data.tags;
    } catch (error) {
        if (error.code === 'failed-precondition' && error.message === 'Insufficient credits') {
            message.info("Buy some more credits on the account page")
        }
        console.error("Error calling function:", error);
        throw error;
    }
}

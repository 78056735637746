import { GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { signInWithPopup, signInWithRedirect, signOut, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { auth, db } from './firebase'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
// import { createUserDocument } from './create_user';
import { getFunctions, httpsCallable } from 'firebase/functions';

const provider = new GoogleAuthProvider();
const functions = getFunctions();
const createOrUpdateUser = httpsCallable(functions, 'createOrUpdateUser');

export const signInWithGoogle = () => signInWithRedirect(auth, provider);

export const signInWithPop = async () => signInWithPopup(auth, provider)

export const signOutWithGoogle = () => signOut(auth);

export const getAuthResult = () => getRedirectResult(auth);

export const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result) {
      // User successfully signed in
      const user = result.user;
      console.log("user:", user);
      // await createOrUpdateUser(user);
    }
  } catch (error) {
    console.error("Error handling redirect result:", error);
  }
};

// Use onAuthStateChanged to observe the user's authentication state
export const onAuthStateChange = (callback) => onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in');
  } else {
    console.log('User is signed out');
  }
});

onAuthStateChanged(auth, async (user) => {
  if (user) {
    // await createUserDocument(user);
    if (process.env.NODE_ENV === 'development') {
    } else {
      await createOrUpdateUser();
    }
  }
});

export const getIdToken = () => {
  return auth.currentUser.getIdToken();
};



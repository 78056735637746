import React, { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react';
import { AlignCenterOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Progress } from 'antd';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { getIdToken } from '../../firebase/Authentication';
import { handleTranscriptionRequest } from '../../firebase/transcribe';
import { useVideo } from '../../context/VideoContext';
import AppContext from '../../context/AppContext';
import { handleTitleRequest } from '../../firebase/genTitle';
import { handleDescriptionRequest } from '../../firebase/genDescription';
import { handleTagsRequest } from '../../firebase/genTags';

const UploadComponent = React.memo(({ onTranscriptionComplete }) => {
    const {
        uploadState, setUploadState,
        file, setFile,
        progress, setProgress,
        fileInputRef,
        ffmpegRef,
        extractedAudioUrl, setExtractedAudioUrl
    } = useVideo();

    const {
        setTranscriptionLoading,
        setTranscription,
        setTranscriptionSRT,

        generatedTitles,
        setTitle,
        setGeneratedTitles,
        setTitleLoading,

        generatedDescriptions,
        setDescription,
        setGeneratedDescriptions,
        setDescriptionLoading,

        generatedTags,
        setTags,
        setGeneratedTags,
        setTagsLoading
    } = useContext(AppContext)

    useEffect(() => {
        loadFFmpeg();
    }, []);

    const loadFFmpeg = useCallback(async () => {
        try {
            const ffmpeg = new FFmpeg();
            ffmpeg.on('progress', ({ progress }) => {
                console.log('FFmpeg progress:', progress);
                setProgress(Math.round(progress * 100));
            });
            ffmpeg.on('log', ({ message }) => {
                console.log('FFmpeg Log:', message);
            });

            // Load FFmpeg
            console.log('Starting FFmpeg load');
            const coreURL = await toBlobURL('https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd/ffmpeg-core.js', 'text/javascript')
                .catch(e => {
                    message.error('Error creating blob URL for core:', e);
                    throw e;
                });
            const wasmURL = await toBlobURL('https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd/ffmpeg-core.wasm', 'application/wasm')
                .catch(e => {
                    message.error('Error creating blob URL for wasm:', e);
                    throw e;
                });

            await ffmpeg.load({ coreURL, wasmURL });

            console.log('FFmpeg loaded successfully');
            ffmpegRef.current = ffmpeg;
        } catch (error) {
            console.error('Error loading FFmpeg:', error);
            message.error(`Failed to load FFmpeg: ${error.message}`);
        }
    }, []);

    const isValidVideoFile = useCallback((file) => {
        const validTypes = ['video/', 'application/mp4', 'video/quicktime'];
        return validTypes.some(type => file.type.startsWith(type));
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && isValidVideoFile(droppedFile)) {
            handleFile(droppedFile);
        } else {
            message.error('Please drop a valid video file.');
        }
    }, []);

    const handleFileInput = useCallback((e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            handleFile(selectedFile);
        }
    }, []);

    const getTitle = useCallback(async (data) => {
        try {
            setTitleLoading(true)
            // Generate initial title
            const initialTitle = await handleTitleRequest(data);
            // const initialTitle = "this is a title"
            setTitleLoading(false);
            setTitle(initialTitle)
            setGeneratedTitles([...generatedTitles, initialTitle]);
            return initialTitle
        } catch (error) {
            console.error('Error generating a title:', error);
            message.error("Failed to generate a title")
            setTitleLoading(false);
        }
    }, [])

    const getDescription = useCallback(async (data) => {
        try {
            setDescriptionLoading(true)
            // Generate initial title
            const initialDescription = await handleDescriptionRequest(data);
            // const initialTitle = "this is a title"
            setDescriptionLoading(false)
            setDescription(initialDescription)
            setGeneratedDescriptions([...generatedDescriptions, initialDescription]);
            return initialDescription
        } catch (error) {
            console.error('Error generating a description:', error);
            message.error("Failed to generate a description")
            setDescriptionLoading(false)
        }
    }, [])

    const getTags = useCallback(async (data) => {
        try {
            setTagsLoading(true)
            // Generate initial title
            const initialTags = await handleTagsRequest(data);
            // const initialTitle = "this is a title"
            setTagsLoading(false)
            setTags(initialTags)
            setGeneratedTags([...generatedTags, initialTags]);
            return initialTags
        } catch (error) {
            console.error('Error generating a title:', error);
            message.error("Failed to generate a title")
            setTitleLoading(false);
            setDescriptionLoading(false)
            setTagsLoading(false)
        }
    }, [])

    const srtToPlainText = (srtString) => {
        // Split the SRT string into individual subtitle entries
        const entries = srtString.trim().split(/\n\s*\n/);

        // Process each entry to extract only the text content
        const textLines = entries.map(entry => {
            // Split each entry into its components
            const parts = entry.split('\n');
            // The text content is always after the timestamp, so we join all parts after the second line
            return parts.slice(2).join(' ').trim();
        });

        // Join all text lines into a single string and escape any problematic characters
        return textLines.join(' ').replace(/[\n\r"'\\]/g, ' ');
    }

    const handleTranscriptionComplete = useCallback(async (data) => {
        if (data) {
            setTranscription(data.text)
            if (data.SRT) {
                setTranscriptionSRT(data.SRT)
            }
            if (!data.text) {
                const plainTextTranscription = srtToPlainText(data)
                console.log("plainTextTranscription", plainTextTranscription)
                setTranscription(data)
                setTranscriptionSRT(data)
                const [title, description, tags] = await Promise.all([
                    getTitle(plainTextTranscription),
                    getDescription(plainTextTranscription),
                    getTags(plainTextTranscription)
                ]);
            } else {
                const [title, description, tags] = await Promise.all([
                    getTitle(data.text),
                    getDescription(data.text),
                    getTags(data.text)
                ]);
            }
        }
    }, []);

    const readFileInChunks = (file, chunkSize = 2 * 1024 * 1024) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let offset = 0;
            const chunks = [];

            const readNextChunk = () => {
                const slice = file.slice(offset, offset + chunkSize);
                reader.readAsArrayBuffer(slice);
            };

            reader.onload = (e) => {
                chunks.push(e.target.result);
                offset += chunkSize;
                if (offset < file.size) {
                    readNextChunk();
                } else {
                    resolve(chunks);
                }
            };

            reader.onerror = reject;

            readNextChunk();
        });
    };

    const handleFile = useCallback(async (file) => {
        setFile(file);
        setUploadState('loading');
        setProgress(0);

        try {
            const chunks = await readFileInChunks(file);
            const audioBlob = await extractAudioFromVideo(chunks);
            const transcription = await sendAudioToWhisper(audioBlob);
            setUploadState('viewVideo');
            await handleTranscriptionComplete(transcription);
        } catch (error) {
            console.error('Error processing video:', error);
            message.error('An error occurred while processing the video.');
            setUploadState('upload');
        }
    }, []);

    const handleFileOld = useCallback(async (file) => {
        setFile(file);
        setUploadState('loading');
        setProgress(0);

        try {
            const audioBlob = await extractAudioFromVideo(file);
            const transcription = await sendAudioToWhisper(audioBlob);
            // const transcription = "this is an example eof transcripton"
            setUploadState('viewVideo');
            const title = await handleTranscriptionComplete(transcription);
        } catch (error) {
            if (error.message === 'Insufficient credits') {
                message.error("Buy credits on the account page")
            } else {
                message.error("Sign up to generate your data")
            }
            console.error('Error processing video:', error);
            // message.error('An error occurred while processing the video.');
            setUploadState('upload');
        }
    }, []);

    // const extractAudioFromVideo = useCallback(async (videoFile) => {
    const extractAudioFromVideo = useCallback(async (chunks) => {
        try {
            const ffmpeg = ffmpegRef.current;
            if (!ffmpeg) {
                throw new Error('FFmpeg is not loaded');
            }
            console.log('Writing file to FFmpeg');
            // await ffmpeg.writeFile('input.mp4', await fetchFile(videoFile));
            // NOW WE TRY IN CHUNKS
            // Write chunks to FFmpeg virtual file system
            for (let i = 0; i < chunks.length; i++) {
                await ffmpeg.writeFile(`input_${i}.mp4`, new Uint8Array(chunks[i]));
                setProgress(Math.round((i / chunks.length) * 50)); // First 50% for writing
            }


            console.log('Executing FFmpeg command');
            // await ffmpeg.exec(['-i', 'input.mp4', '-vn', '-acodec', 'libmp3lame', '-b:a', '128k', 'output.mp3']);

            // Concatenate chunks and extract audio
            let concatCommand = 'concat:';
            for (let i = 0; i < chunks.length; i++) {
                concatCommand += `input_${i}.mp4|`;
            }
            concatCommand = concatCommand.slice(0, -1); // Remove last |
            await ffmpeg.exec(['-i', concatCommand, '-vn', '-acodec', 'libmp3lame', '-b:a', '128k', 'output.mp3']);
            setProgress(75); // 75% after extraction

            console.log('Reading output file');
            const data = await ffmpeg.readFile('output.mp3');
            const audioBlob = new Blob([data.buffer], { type: 'audio/mp3' });
            
            setProgress(100);
            // Create a URL for the audio blob
            console.log('Creating audio URL');
            const audioUrl = URL.createObjectURL(audioBlob);
            setExtractedAudioUrl(audioUrl);
            return audioBlob;
        } catch (error) {
            message.error('Error in extractAudioFromVideo');
            console.error('Error in extractAudioFromVideo:', error);
            throw error;
        }
    }, []);

    const sendAudioToWhisper = useCallback(async (audioBlob) => {
        try {
            // const formData = new FormData();
            // formData.append('audio', audioBlob, 'audio.mp3');

            // const idToken = await getIdToken(); // Implement this function to get the user's ID token
            setTranscriptionLoading(true)
            const transcription = await handleTranscriptionRequest(audioBlob)
            console.log("transcription ", transcription)
            setTranscriptionLoading(false)
            return transcription;
        } catch (error) {
            setTranscriptionLoading(false)
            console.error('Error sending audio to Whisper:', error);
            throw error;
        }
    }, []);


    const handleRemoveVideo = useCallback(() => {
        setFile(null);
        setUploadState('upload');
        if (extractedAudioUrl) {
            URL.revokeObjectURL(extractedAudioUrl);
            setExtractedAudioUrl(null);
        }
        setTitle("Input a video at the top place to get your YouTube title")
        setGeneratedTitles([])
        setDescription("Input a video at the top place to get your YouTube description")
        setGeneratedDescriptions([])
        setTags(["Input a video at the top place to get your YouTube tags"])
        setGeneratedTags([])
        setTranscription("Input a video at the top place to get your video transcription and subtitle file")
        setTranscriptionSRT("")
    }, []);

    const uploadAreaStyle = useMemo(() => ({
        border: '2px dashed #d9d9d9',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s',
        backgroundColor: "white",
        height: "250px",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }), []);

    const renderUploadArea = useMemo(() => (
        <div
            style={uploadAreaStyle}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => fileInputRef.current.click()}
        >
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInput}
                accept="video/*"
                style={{ display: 'none' }}
            />
            <UploadOutlined style={{ fontSize: '64px', color: 'gray' }} />
            <p>Drop your video here or click to upload</p>
        </div>
    ), [uploadAreaStyle, handleDrop, fileInputRef, handleFileInput]);

    const renderLoading = useMemo(() => (
        <div style={{ textAlign: 'center', height: "250px", alignContent: "center" }}>
            <Progress percent={progress} status="active" />
            <p>Processing video...</p>
        </div>
    ), [progress]);

    const renderVideo = useMemo(() => (
        <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
            <div style={{ position: 'relative', textAlign: 'center', width: "fit-content" }}>
                <Button
                    icon={<DeleteOutlined />}
                    onClick={handleRemoveVideo}
                    style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 1,
                    }}
                />
                <video
                    controls
                    src={file ? URL.createObjectURL(file) : ''}
                    style={{ maxWidth: '100%', maxHeight: '350px', borderRadius: "8px" }}
                />
            </div>
        </div>
    ), [file, handleRemoveVideo]);

    if (uploadState === 'upload') {
        return renderUploadArea;
    } else if (uploadState === 'loading') {
        return renderLoading;
    } else if (uploadState === 'viewVideo') {
        return renderVideo;
    }
});

export default UploadComponent;
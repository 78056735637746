import { CopyOutlined, LeftOutlined, LoadingOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react';
import { Spin, message } from 'antd';
import AppContext from '../../context/AppContext';
import { handleTagsRequest } from '../../firebase/genTags';

const words = ["transcription", "description", "title", "tags"];


const TagsComponent = () => {
    // const [currentTags, setCurrentTags] = useState("travel, adventure, jungle, sumatra, indonesia");
    const [allTags, setAllTags] = useState(["travel, adventure, jungle, sumatra, indonesia", "asdsf", "another new title", "you generated a youtube title"]);

    const {
        currentTags,
        setTags,
        generatedTags,
        setGeneratedTags,
        tagsLoading,
        setTagsLoading,
        transcriptionText
    } = useContext(AppContext)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeout(() => {
                // setIndex((prevIndex) => (prevIndex + 1) % words.length);
                // setFade(false);
            }, 200); // Wait for fade out, then change word
        }, 1000); // Change every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    const copyToClipboard = () => {
        if (currentTags) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                // Modern browsers
                navigator.clipboard.writeText(currentTags)
                    .then(() => {
                        message.success('Tags copied to clipboard');
                    })
                    .catch(err => {
                        fallbackCopyTextToClipboard(currentTags);
                    });
            } else {
                // Fallback for older browsers and some mobile devices
                fallbackCopyTextToClipboard(currentTags);
            }
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                message.success('Tags copied to clipboard');
            } else {
                message.error('Failed to copy Tags');
            }
        } catch (err) {
            message.error('Failed to copy Tags');
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const displayCopyIcon = () => {
        // if (generatedTags.length > 0) {
        return (
            <div style={{ paddingLeft: "16px" }}>
                <CopyOutlined onClick={copyToClipboard} style={{ fontSize: "24px", color: "#777777" }} />
            </div>
        )
        // } else {
        // return null
        // }
    }

    const displayLeftIcon = () => {
        const currentIndex = generatedTags.indexOf(currentTags) ?? 0;
        if (generatedTags?.length > 0 && currentIndex !== 0) {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <LeftOutlined onClick={selectPrevious} style={{ fontSize: "24px", color: "#777777" }}></LeftOutlined>
                </div>
            )
        } else {
            return null
        }
    }

    const handleAddTags = async () => {
        try {
            setTagsLoading(true)
            // Generate initial tags
            const initialTags = await handleTagsRequest(transcriptionText);
            // const initialTags = "add a new title"
            setTagsLoading(false);
            setTags(initialTags)
            setGeneratedTags([...generatedTags, initialTags]);
            return initialTags
        } catch (error) {
            if (error.message === 'Insufficient credits') {
                message.error("Buy credits on the account page")
            } else {
                message.error("Failed to generate a tags")
            }
            console.error('Error generating a tags:', error);
            setTagsLoading(false);
        }
    }

    const displayRightIcon = () => {
        if (currentTags === generatedTags?.[generatedTags?.length - 1]) {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <PlusOutlined onClick={handleAddTags} style={{ fontSize: "24px", color: "#777777" }}></PlusOutlined>
                </div>
            )
        } else if (generatedTags?.length < 1 && transcriptionText !== 'Input a video at the top place to get your video transcription and subtitle file') {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <PlusOutlined onClick={handleAddTags} style={{ fontSize: "24px", color: "#777777" }}></PlusOutlined>
                </div>
            )
        } else if (generatedTags?.length < 1) {
            return null
        } else {
            return (
                <div style={{ paddingLeft: "16px" }}>
                    <RightOutlined onClick={selectNext} style={{ fontSize: "24px", color: "#777777" }}></RightOutlined>
                </div>
            )
        }
    }

    const displayLoading = () => {
        return (
            <div style={{ alignItems: "center", display: "flex" }}>
                <Spin indicator={<LoadingOutlined spin />} style={{ paddingRight: "16px" }} /> Loading...
            </div>
        )
    }

    const selectPrevious = () => {
        const currentIndex = generatedTags?.indexOf(currentTags) ?? 0;

        if (currentIndex !== -1) {
            const previousIndex = (currentIndex - 1 + generatedTags?.length) % generatedTags?.length;
            setTags(generatedTags[previousIndex]);
        }
    }
    const selectNext = () => {
        const currentIndex = generatedTags.indexOf(currentTags);

        if (currentIndex !== -1) {
            const nextIndex = (currentIndex + 1) % generatedTags?.length;
            setTags(generatedTags[nextIndex]);
        }
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <h2 style={{ color: "#3C3C3C" }}>Tags</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {displayCopyIcon()}
                    {displayLeftIcon()}
                    {displayRightIcon()}
                </div>
            </div>
            <div style={{ border: "solid lightgray 1px", borderRadius: "8px", backgroundColor: "white", width: "100%", padding: "16px" }}>
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C" }}>{tagsLoading ? displayLoading() : currentTags?.join(', ')}</span>
            </div>
        </div>
    );
};

export default TagsComponent;
import { message } from "antd";
import { auth } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export async function handleDescriptionRequest(transcriptionText) {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Call the Firebase function
    const generateDescription = httpsCallable(functions, 'generateDescription');
    try {
        const result = await generateDescription({ transcription: transcriptionText });
        console.log("generateDescription result :", result)
        console.log("generateDescription result.data.tags :", result.data.description)
        return result.data.description;
    } catch (error) {
        if (error.code === 'failed-precondition' && error.message === 'Insufficient credits') {
            message.info("Buy some more credits on the account page")
        }
        console.error("Error calling function:", error);
        throw error;
    }
}

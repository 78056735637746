import React, { useState, useEffect } from 'react';

const words = ["transcription", "description", "title", "tags"];


const HeroComponent = () => {
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(true);
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % words.length);
                setFade(false);
            }, 200); // Wait for fade out, then change word
        }, 1000); // Change every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
            <div style={{}}>
                <h1 style={{marginBottom: "0px", lineHeight: "50px"}}>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '36px',
                        color: '#3B3B3B'
                    }}>Drop your video to </span>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '36px',
                        color: 'white',
                        backgroundColor: "#FF4E4E",
                        borderRadius: "8px",
                        padding: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px"

                    }}>Generate</span>
                </h1>
            </div>
            <h1 style={{
                marginTop: "0px",
                fontWeight: 'bold',
                fontSize: '36px',
                color: '#3B3B3B',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline'
            }}>
                <span style={{ paddingLeft: "32px" }}>your </span>
                <span
                    style={{
                        paddingLeft: "8px",
                        display: 'inline-block',
                        width: '240px', // Adjust this value based on your longest word
                        textAlign: 'left',
                        transition: 'opacity 0.2s ease-in-out',
                        opacity: fade ? 0 : 1,
                    }}
                >
                    {words[index]}
                </span>
            </h1>

            <h2 style={{
                fontWeight: '400',
                fontSize: '24px',
                color: '#777777'
            }}>For easy YouTube posting</h2>
        </div>
    );
};

export default HeroComponent;
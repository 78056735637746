import React, { useContext, useEffect, useRef, useState } from 'react';
import AppHeader from '../components/Header';
import HeroComponent from '../components/Hero/HeroComponent';
import { Col, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import UploadComponent from '../components/Upload/UploadComponent';
import TitleComponent from '../components/Title/TitleComponent';
import DescriptionComponent from '../components/Description/DescriptionComponent';
import TagsComponent from '../components/Tags/TagsComponent';
import TranscriptionComponent from '../components/Transcription/TranscriptionComponent';
import { handleTitleRequest } from '../firebase/genTitle';
import { useAuth } from '../context/AuthContext';
import AppStae from '../context/AppState';
import AppContext from '../context/AppContext';
import { VideoProvider } from '../context/VideoContext';

const Home = () => {
    const { user, loading } = useAuth();
    // const [transcriptionData, setTranscriptionData] = useState({ "text": "default transcription" });
    const [selectedTitle, setSelectedTitle] = useState("This is a placeholder title");
    const isFirstRender = useRef(true);
    // console.log(transcriptionData)

    const {
        // Title-related state
        currentTitle,
        generatedTitles,
        titleLoading,

        // Description-related state
        currentDescription,
        generatedDescriptions,
        descriptionLoading,

        // Tags-related state
        currentTags,
        generatedTags,
        tagsLoading,

        // Transcription-related state
        transcriptionText,
        transcriptionSrt,
        transcriptionLoading,

        // User-related state
        // user,
        // credits,
        // subscription,

        // // Video-related state
        // currentVideo: null,
        // videoProcessing: false,

        // Error state
        error,
        setTitle,
        setGeneratedTitles,
        setTitleLoading,
        setDescription,
        setGeneratedDescriptions,
        setDescriptionLoading,
        setTags,
        setGeneratedTags,
        setTagsLoading,
        setTranscription,
        setTranscriptionSRT,
        setTranscriptionLoading,
        setUser,
        setTranscriptionCredits,
        setGenerationCredits,
        setSubscription,
        setError,
    } = useContext(AppContext)

    const handleTranscriptionComplete = (data) => {
        if (data) {
            setTranscription(data.text)
            if (data.SRT) {
                setTranscriptionSRT(data.SRT)
            }
            // setTranscriptionData(data);
        }
    };

    // useEffect(() => {
    //     if (isFirstRender.current) {
    //         isFirstRender.current = false;
    //         return;
    //     }

    //     // if (user) {
    //     //     console.log("useEffect transcriptionData")
    //     //     const title = handleTitleRequest(transcriptionData)
    //     //     console.log("new Title : ", title)
    //     //     setSelectedTitle(title);
    //     // }

    // }, [transcriptionData])

    return (
        <div style={{ backgroundColor: "#FFF7F5" }}>

            <AppHeader />
            <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                <Col xs={23} sm={23} md={20} xl={16} xxl={10}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <HeroComponent />
                    <br />
                    <br />
                    <br />
                    <br />
                    <UploadComponent onTranscriptionComplete={handleTranscriptionComplete} />
                    <br />
                    <br />
                    <TranscriptionComponent />
                    <br />
                    <br />
                    <TitleComponent />
                    <br />
                    <br />
                    <DescriptionComponent />
                    <br />
                    <br />
                    <TagsComponent />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* Add your components here */}
                </Col>
            </div>
        </div>
    );
};

export default Home;
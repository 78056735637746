import React, { useState, useRef, useEffect } from 'react';
import { Layout, Button, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signOutWithGoogle, getAuthResult, onAuthStateChange } from '../../firebase/Authentication'
import { useAuth } from '../../context/AuthContext';
import { getUserCredits } from '../../firebase/create_user';
import SubscriptionButton, { initiateSubscription } from '../../services/SubscriptionManager';
import PricingComponent from '../Pricing/PricingComponent';

const { Header } = Layout;

const AccountComponent = () => {
    const { user, loading } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [transcriptionCredits, setTranscriptionCredits] = useState(0);
    const [generationCredits, setGenerationCredits] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const setCreditsState = async () => {
            if (user) {
                const { transcriptionCredits, generationCredits } = await getUserCredits(user)
                setTranscriptionCredits(transcriptionCredits)
                setGenerationCredits(generationCredits)
            } else {
                setTranscriptionCredits(0)
                setGenerationCredits(0)
            }
        }
        setCreditsState()
    }, [])


    useEffect(() => {
        const get = async () => {
            const response = await getAuthResult()
            const response2 = await onAuthStateChange()
            console.log("redirect result : ", response)
            console.log("redirect result2 : ", response2)
        }
        get()
    }, [])

    const handleHome = () => {
        navigate('/');
    }

    const handleAccount = () => {
        // setUser("none")

        navigate('/account');
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        // open sign up modal
        // sign up with google
        // pay with lemonsqueezy
        setIsModalVisible(true)
        // setUser("loggedIn")
    };

    const userButton = () => {
        if (user === "loggedIn") {
            return (
                <Button type="primary" onClick={handleAccount}>Account</Button>
            )
        } else if (user === null) {
            return (
                <Button type="primary" onClick={handleSignUp}>Sign Up</Button>
            )
        }
    }

    const handleSubscribe = () => {
        if (user) {
            initiateSubscription(user.uid);
        } else {
            // Handle case when user is not logged in
            console.log('Please log in to subscribe');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>Please sign in</div>;
    }

    return (
        <div style={{
            flexDirection: "column",
            display: "flex"

        }}>
            <span style={{ fontSize: "32px", textAlign: "center", fontWeight: "600", color: "#3C3C3C" }}>Account</span>
            <br />
            <br />
            <h2 style={{ color: "#3C3C3C", marginBottom: 0 }}>Logged in as</h2>
            <br />
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C" }}>{user.email}</span>
            </div>
            <h2 style={{ color: "#3C3C3C", marginBottom: 0 }}>Credits</h2>
            <br />
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                    <div style={{ width: "50%", justifyContent: "space-between", display: "flex" }}>
                        <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C" }}>Transcription</span>
                        <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C", paddingLeft: "16px" }}>{transcriptionCredits}</span>
                    </div>
                    <br />
                    <div style={{ width: "50%", justifyContent: "space-between", display: "flex" }}>
                        <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C" }}>Generation</span>
                        <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C", paddingLeft: "16px" }}>{generationCredits}</span>
                    </div>
                </div>
                {/* <SubscriptionButton userId={user.id} /> */}
                {/* <Button onClick={() => handleSubscribe} style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C", padding: "16px" }}>Buy more</Button> */}
            </div>
            {/* <h2 style={{ color: "#3C3C3C", marginBottom: 0 }}>Generation Credits</h2>
            <br />
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", fontWeight: "400", color: "#3C3C3C" }}>{credits}</span>
                <Button style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C", padding: "16px" }}>Buy more</Button>
            </div> */}
            <br />
            <br />
            <br />
            <br />
            <Button onClick={signOutWithGoogle} style={{ fontSize: "20px", padding: "20px" }}>
                Log out
            </Button>
            <br />
            <br />
            <br />
            <br />
            <PricingComponent />
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{
                    border: "1px solid lightgray", borderRadius: "8px", backgroundColor: "white",
                    display: 'flex', flexDirection: "column",
                    padding: "16px", alignItems: "center"
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "700", color: "#3C3C3C" }}>Subscription</span>
                    <br />
                    <span style={{ fontSize: "16px", fontWeight: "400", color: "#3C3C3C" }}>Get 50 video transcriptions each month and loads of generations. You won't have to worry about writing youtube titles, descriptions or tags anymore!</span>
                    <br />
                    <span style={{ fontSize: "20px", fontWeight: "400", color: "#3C3C3C" }}>- 50 transcription</span>
                    <span style={{ fontSize: "20px", fontWeight: "400", color: "#3C3C3C" }}>- 1000 generations</span>
                    <br />
                    <br />
                    <span style={{ fontSize: "20px", fontWeight: "300", color: "#3C3C3C" }}>$8.88 / month</span>
                    <br />
                    <br />
                    <div style={{ width: "100%", border: "1px solid lightgray", borderRadius: "8px", padding: "8px", display: "flex", justifyContent: "center" }}>
                        <span style={{ fontSize: "20px", fontWeight: "500", color: "#3C3C3C" }}>Pay with Stripe</span>

                    </div>
                </div>
                <div style={{
                    border: "1px solid lightgray", borderRadius: "8px", backgroundColor: "white",
                    display: 'flex', flexDirection: "column",
                    padding: "16px", alignItems: "center"
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "700", color: "#3C3C3C" }}>One time</span>
                    <br />
                    <span style={{ fontSize: "16px", fontWeight: "400", color: "#3C3C3C" }}>Get 50 video transcriptions each month and loads of generations. You won't have to worry about writing youtube titles, descriptions or tags anymore!</span>
                    <br />
                    <span style={{ fontSize: "20px", fontWeight: "400", color: "#3C3C3C" }}>- 50 transcription</span>
                    <span style={{ fontSize: "20px", fontWeight: "400", color: "#3C3C3C" }}>- 1000 generations</span>
                    <br />
                    <br />
                    <span style={{ fontSize: "20px", fontWeight: "300", color: "#3C3C3C" }}>$8.88 / month</span>
                    <br />
                    <br />
                    <div style={{ width: "100%", border: "1px solid lightgray", borderRadius: "8px", padding: "8px", display: "flex", justifyContent: "center" }}>
                        <span style={{ fontSize: "20px", fontWeight: "500", color: "#3C3C3C" }}>Pay with Stripe</span>

                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default AccountComponent;
import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import Home from './page/Home';
import Account from './page/Account';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './routes/PrivateRoute';
import { handleRedirectResult, onAuthStateChange } from './firebase/Authentication';
import AppState from './context/AppState';
import { VideoProvider } from './context/VideoContext';

const { Content } = Layout;

function App() {
  // useEffect(() => {
  //   handleRedirectResult();

  //   const unsubscribe = onAuthStateChange((user) => {
  //     if (user) {
  //       console.log('User is signed in:', user);
  //       // You can update your app state here
  //     } else {
  //       console.log('User is signed out');
  //       // You can update your app state here
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  return (
    <AuthProvider>
      <AppState>
        <VideoProvider>
          <Router>
            <Layout>
              <Content>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/account" element={
                    <PrivateRoute>
                      <Account />
                    </PrivateRoute>
                  } />
                </Routes>
              </Content>
            </Layout>
          </Router>
        </VideoProvider>
      </AppState>
    </AuthProvider>
  );
}

export default App;

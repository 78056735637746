const AppReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TITLE':
            return { ...state, currentTitle: action.payload };
        case 'SET_GENERATED_TITLES':
            return { ...state, generatedTitles: action.payload };
        case 'SET_TITLE_LOADING':
            return { ...state, titleLoading: action.payload };

        case 'SET_DESCRIPTION':
            return { ...state, currentDescription: action.payload };
        case 'SET_GENERATED_DESCRIPTIONS':
            return { ...state, generatedDescriptions: action.payload };
        case 'SET_DESCRIPTION_LOADING':
            return { ...state, descriptionLoading: action.payload };

        case 'SET_TAGS':
            return { ...state, currentTags: action.payload };
        case 'SET_GENERATED_TAGS':
            return { ...state, generatedTags: action.payload };
        case 'SET_TAGS_LOADING':
            return { ...state, tagsLoading: action.payload };

        case 'SET_TRANSCRIPTION':
            return { ...state, transcriptionText: action.payload };
        case 'SET_TRANSCRIPTION_SRT':
            return { ...state, transcriptionSrt: action.payload };
        case 'SET_TRANSCRIPTION_LOADING':
            return { ...state, transcriptionLoading: action.payload };
        //   case 'SET_USER':
        //     return { ...state, user: action.payload };
        //   case 'SET_CREDITS':
        //     return { ...state, credits: action.payload };
        //   case 'SET_SUBSCRIPTION':
        //     return { ...state, subscription: action.payload };
        default:
            return state;
    }
};

export default AppReducer;
import { message } from "antd";
import { auth } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export async function handleTranscriptionRequest(audioBlob) {

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Convert audio file to base64
    // const base64Audio = await fileToBase64(audioFile);
    // Convert audio blob to base64
    const base64Audio = await blobToBase64(audioBlob);

    // Call the Firebase function
    const transcribeAudio = httpsCallable(functions, 'transcribeAudio');
    try {

        const result = await transcribeAudio({ audio: base64Audio });
        return result.data.transcription;
    } catch (error) {
        if (error.code === 'failed-precondition' && error.message === 'Insufficient credits') {
            message.info("Buy some more credits on the account page")
        }
        console.error("Error calling function:", error);
        throw error;
    }
}

function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
    });
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}